import { useAppDispatch } from "state/store";
import { SortableListProps } from "./sortable-list.props";
import { useSelector } from "react-redux";
import { getTocs, setCarePlanData } from "state/features/toc/toc.slice";
import update from "immutability-helper";
import SortableListPresentation from "./sortable-list-component";
import TocEmptyImage from "shared/assets/images/toc-empty-state.svg";
import "./sortable-list.styles.scss";

const SortableList = (props: SortableListProps) => {
  const dispatch = useAppDispatch();
  const { carePlanData } = useSelector(getTocs);
  const { toc, intakeType } = props;

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newCarePlan = update(carePlanData, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, carePlanData[dragIndex] as any],
      ],
    });
    dispatch(setCarePlanData(newCarePlan));
  };

  return carePlanData.length > 0 ? (
    <SortableListPresentation
      key={1}
      list={carePlanData}
      moveCard={moveCard}
      toc={toc}
      intakeType={intakeType}
    />
  ) : (
    <div className="toc-empty-image">
      <img src={TocEmptyImage} alt="toc-empty" />
      <div className="empty-message-container">
        <div className="empty-message">No Care Plan available to show</div>
        <div className="empty-message-subline">
        </div>
      </div>
    </div>
  );
};
export default SortableList;
