import Icon from "components/Icon";
import { SortableListPresentationProps } from "./sortable-list.props";
import Row from "./sortable-row.component";

const SortableListPresentation = (props: SortableListPresentationProps) => {
  const { list, moveCard, toc, intakeType } = props;
  return (
    <div className="sortable-container">
      <header className="sortable-header">
        <div></div>
        <div className="services">Services</div>
        <div className="provider">Provider</div>
        <div className="los">LOS/Visits</div>
      </header>
      <div className="sortable-row-container">
        {list.map((l, i: number) => (
          <Row
            key={l.longName}
            item={l}
            index={i}
            moveCard={moveCard}
            toc={toc}
            intakeType={intakeType}
          />
        ))}
      </div>
      <div className="legend-description">
        <div className="preferred-legend">
          <Icon icon="preferred" size={16} />
          <div className="preferred-legend-text">Preferred</div>
        </div>
        <div className="privileged-legend">
          <Icon icon="privileged" size={16} />
          <div className="privileged-legend-text">Privileged</div>
        </div>
      </div>
    </div>
  );
};

export default SortableListPresentation;
